import { createTheme } from '@mui/material/styles';

function pxToRem(value) {
  return `${value / 16}rem`;
}

let theme = createTheme({
  typography: {
    fontFamily: 'raisonne-regular-pro',
    fontWeight: '400',
    htmlFontSize: 16,
    useNextVariants: true,
  },
  palette: {
    primary: {
      main: '#002745',
    },
    secondary: {
      main: '#000',
    },
    background: {
      default: '#fff',
      primary: '#f2f2f2',
    },
    text: {
      primary: '#333333',
      secondary: '#002745',
      white: '#fff',
      grey: '#999999',
    },
  },
});

theme = createTheme(theme, {
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          [theme.breakpoints.up('md')]: {
            paddingLeft: '3vw',
            paddingRight: '3vw',
          },
        },
      },
    },
    MuiTypography: {
      variants: [
        {
          props: { variant: 'navitem' },
          style: {
            fontSize: pxToRem(24),
          },
        },
        {
          props: { variant: 'navitemmobile' },
          style: {
            fontSize: pxToRem(20),
          },
        },
        {
          props: { variant: 'body3' },
          style: {
            fontFamily: 'Work Sans, sans-serif',
            fontSize: pxToRem(14),
            fontWeight: '500',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 1.64,
            letterSpacing: 'normal',
          },
        },
        {
          props: { variant: 'btnDefault' },
          style: {
            fontSize: pxToRem(22),
            fontWeight: '600',
          },
        },
      ],
      styleOverrides: {
        h1: {
          fontSize: pxToRem(60),
          fontWeight: '600',
          [theme.breakpoints.down('md')]: {
            fontSize: pxToRem(50),
          },
        },
        h2: {
          fontSize: pxToRem(40),
          fontWeight: '600',
          [theme.breakpoints.down('md')]: {
            fontSize: pxToRem(30),
          },
        },
        h3: {
          fontSize: pxToRem(30),
          fontWeight: '600',
          [theme.breakpoints.down('md')]: {
            fontSize: pxToRem(20),
          },
        },
        h4: {
          fontSize: pxToRem(25),
          fontWeight: '600',
          [theme.breakpoints.down('md')]: {
            fontSize: pxToRem(20),
          },
        },
        h5: {
          fontSize: pxToRem(18),
          fontWeight: '600',
          [theme.breakpoints.down('md')]: {
            fontSize: pxToRem(18),
          },
        },
        h6: {
          fontSize: pxToRem(16),
          fontWeight: '600',
          [theme.breakpoints.down('md')]: {
            fontSize: pxToRem(16),
          },
        },
        body1: {
          fontFamily: 'Work Sans, sans-serif',
          fontSize: pxToRem(18),
          fontWeight: 'normal',
          fontStretch: 'normal',
          fontStyle: 'normal',
          lineHeight: 1.56,
          letterSpacing: '-0.13px',
        },
        body2: {
          fontFamily: 'Work Sans, sans-serif',
          fontSize: pxToRem(18),
          fontWeight: '500',
          fontStretch: 'normal',
          fontStyle: 'normal',
          lineHeight: 1.56,
          letterSpacing: '-0.13px',
        },
        subtitle1: {
          fontSize: pxToRem(28),
          fontWeight: '600',
        },
        button: {
          fontSize: pxToRem(20),
          fontWeight: '600',
          textTransform: 'inherit',
        },
      },
    },
  },
});

export default theme;
