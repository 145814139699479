const Reducer = (state, action) => {
  switch (action.type) {
    case 'SET_MENU':
      return {
        ...state,
        menu: action.payload,
      };
    case 'SET_ENQUIRY_OVERLAY':
      return {
        ...state,
        enquiry_overlay: action.payload,
      };

    default:
      return state;
  }
};

export default Reducer;
